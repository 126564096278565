html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
    font-size: 0.3rem;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
i {
    font-style: italic;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
html {
    font-size: 50px;
}
@media (max-width: 1200px) {
    html {
        font-size: 45px;
    }
}
@media (max-width: 860px) {
    html {
        font-size: 42px;
    }
}
@media (max-width: 500px) {
    html {
        font-size: 38px;
    }
}
body {
    background: #fff;
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.3;
}
* {
    box-sizing: border-box;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}
::-webkit-scrollbar {
    width: 9px;
    background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
    background-color: rgb(187, 8, 8);
    background-image: -webkit-linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
}
a {
    color: red;
    text-decoration: none;
}
.bold,
.strong,
b,
strong {
    font-weight: bold;
}
img {
    max-width: 100%;
    height: auto;
}
.App {
    margin: 0 auto;
    position: relative;
    min-height: 100vh;
    box-shadow: 0 0 0.13333rem 0 rgba(125, 124, 124, 0.3);
    background: #3d0101;
    overflow-x: hidden;
}
.login {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
}
input:focus,
select:focus,
select:active {
    outline: 0.01333rem solid #b72b2b;
    -webkit-user-select: text;
    user-select: text;
}
.title {
    color: #ca5959;
    text-align: left;
    font-size: 0.4rem;
    padding: 0.32rem;
}
.App:has(.login) {
    background: #5f0202;
}
/*.login {
    padding: 0 0.32rem;
    background-image: url(./images/bg-login.png);
    background-size: 100%;
    background-repeat: no-repeat;
    background-color: #5f0202;
}*/
.login > div {
    display: flex;
}
.logo-login {
    width: 25%;
    max-width: 150px;
    position: absolute;
    z-index: 2;
    top: 5px;
    left: 5px;
}
.banner-login {
    width: 100%;
}
.login-return {
    color: #fff;
    font-size: 0.28rem;
    text-align: left;
    display: flex;
    align-items: center;
    cursor: pointer;
}
svg.login-return-i {
    font-size: 0.8rem;
    width: 0.8rem;
    height: 0.8rem;
}
.form-lg input {
    width: 90%;
    height: 1.30333rem;
    padding: 0.36rem 0.34667rem;
    font-size: 0.36333rem;
    border: none;
    border-radius: 0.26667rem;
    background: #360101;
    color: #fff;
}
.form-lg input[type="radio"] {
    width: 15px;
    height: 15px;
    outline: none !important;
}
input[type="radio"]:focus,
input[type="radio"]:active {
    outline: none !important;
    border: 0 !important;
}
.login form input {
    padding: 0.3rem 0.34667rem 0.3rem 1.34667rem;
}
.form-lg img {
    position: absolute;
    left: 1rem;
    top: 51%;
    transform: translateY(-50%);
    height: 0.5rem;
}
.form-lg input::placeholder {
    color: #952020;
}
.form-lg label {
    margin: 0.2rem auto 0.1rem;
    text-align: left;
    max-width: 90%;
    display: block;
}
.form-lg {
    /*margin-top: 7.5rem;*/
    margin-top: 0.5rem;
    width: 100%;
}
/*@media (max-width: 1200px) {
    .form-lg {
        margin-top: 8.4rem;
    }
}
@media (max-width: 860px) {
    .form-lg {
        margin-top: 8.8rem;
    }
}
@media (max-width: 500px) {
    .form-lg {
        margin-top: 7.8rem;
    }
}*/
.form-lg .inputs {
    padding-bottom: 0.2rem;
}
.form-lg p:not(:empty) {
    color: #fdee95;
    font-size: 0.3rem;
    margin: 0.1rem 0 0.25rem;
}
.form-lg .p-lg {
    display: flex;
    color: #fff;
    font-size: 0.28rem;
    padding: 0.1rem 7% 0.2rem;
    justify-content: right;
}
.form-lg a {
    color: #fff;
}
.form-lg button {
    height: 1.16667rem;
    color: #fff;
    font-size: 0.38rem;
    letter-spacing: 0.03333rem;
    border-radius: 1.06667rem;
    border: none;
    background: #fb0e02;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0.5rem auto 0.3rem;
    position: relative;
    overflow: hidden;
    transition: all 1s ease;
    cursor: pointer;
}
.form-lg button:before {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
    background: transparent;
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0) 0, hsla(0, 0%, 100%, 0.5));
    transform: skew(-25deg);
}
.form-lg button:hover:before {
    animation: shine-160baab6 1s;
}
.form-lg button:hover {
    opacity: 0.8;
    transition: all 1s ease;
}
@keyframes shine-160baab6 {
    to {
        left: 125%;
    }
}
.form-lg .input {
    position: relative;
    margin: 0 0 0.2rem;
}
.login form .inputs svg {
    font-size: 0.5rem;
    color: #666;
    right: 0.8rem;
    top: 52%;
    transform: translateY(-50%);
    position: absolute;
}
.main {
    padding: 0 0.32rem 2rem;
}
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0.32rem;
    background: #1b0101;
    color: #fff;
}
.header a {
    display: block;
    float: left;
}
.header .logo img {
    max-height: 1.2rem;
    width: auto;
    float: left;
}
.header-right {
    display: flex;
    justify-content: right;
    font-size: 0.3333rem;
}
.header-right .header-profile {
    text-align: right;
}
.header-right .header-profile span {
    color: #dc0708;
    display: block;
    margin-bottom: 0.1rem;
}
.header-right .header-profile small {
    color: #ff568f;
    font-size: 0.222rem;
    position: relative;
    top: -0.1rem;
}
.main .swiper {
    border-radius: 8px;
}
.box-game {
    background: #1b0106;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0.2rem;
    color: #f7f6f6;
}
.box-game .active {
    color: #dc0708;
}
.box-game img {
    height: 0.8rem;
}
.box-game span {
    display: block;
    margin-top: 0.05rem;
}
.baohanh {
    padding: 1rem 0.32rem;
}
.nangcap {
    font-size: 0.5rem;
    font-weight: 700;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#f9e508, #fb9209);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.box-miba {
    display: flex;
    padding: 0.2rem;
    border: 2px solid #f5c25f;
    border-radius: 0.3rem;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
}
.box-miba-left {
    width: 30%;
    padding-right: 0.25rem;
}
.box-miba-right {
    width: 70%;
}
.dat-cuoc {
    border-radius: 0.5rem;
    background: #fc0f03;
    color: #f7f6f6;
    text-align: center;
    padding: 0.15rem;
    margin: 0.1rem;
    cursor: pointer;
}
.box-text {
    background: #620101;
    border-radius: 5px;
    color: #fefafa;
    font-size: 0.3rem;
    padding: 0.2rem 0.32rem;
    text-align: left;
    margin-top: 0.2rem;
    position: relative;
}
.box-miba-kq {
    display: flex;
    justify-content: space-around;
    padding-top: 0.2rem;
}
.box-miba-kq .ball {
    padding: 0.15rem 0.2rem;
}
.list-game-xs {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.list-game-xs > * {
    padding: 0 0.2rem;
    width: 33.33%;
}
.tab-navigation {
    line-height: 0.96rem;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    flex-flow: row wrap;
    margin: 0.3rem auto 0;
}
.tab-navigation > li {
    flex: 1;
    height: 100%;
    background: #f1f0f0;
    border-radius: 0.21333rem;
    font-size: 0.37333rem;
    color: #333;
    margin: 0 0.32rem;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    font-weight: 600;
}
.tab-navigation > li.active {
    background: #fd0303;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    color: #fff;
}
.tab-content {
    font-size: 0.333rem;
    margin: 0.32rem;
    background: #fff;
    border-radius: 0.13333rem;
    overflow: hidden;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
}
.title-top {
    background: #1b0101;
    padding: 0.3rem 0.32rem;
    text-align: center;
    position: relative;
}
.title-top h1 {
    font-size: 0.45rem;
    font-weight: 700;
    color: #fff;
    margin: auto;
}
.title-top .back {
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    left: 0.1rem;
    color: #f7f6f6;
}
.title-top .back svg {
    font-size: 0.8rem;
}
.title-top .history {
    position: absolute;
    top: 52%;
    transform: translateY(-50%);
    right: 0.2rem;
    color: #f7f6f6;
    display: flex;
    align-items: center;
    font-size: 0.35rem;
}
.title-top .history img {
    height: 0.6rem;
    width: 0.6rem;
    margin-right: 0.1rem;
}
table {
    width: 100%;
    border-collapse: collapse;
}
table th,
table td {
    padding: 0.2rem;
}
table thead {
    background: linear-gradient(125.43deg, #ff4c4c 12.38%, #f56464 87.13%);
    color: #fff;
    font-size: 0.4rem;
    font-weight: 500;
}
table tbody td {
    text-align: left;
}
table tbody td:last-child {
    text-align: right;
}
table tr td {
    border-bottom: 1px solid #ebebeb !important;
}
table thead th {
    background-color: #f5f5f5;
    font-weight: bold;
}
table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}
table tbody tr:hover {
    background-color: #ebebeb;
}
.award_tb {
    max-height: 10.3rem;
    overflow: auto;
}
.redball {
    background: red;
    color: #fff;
    margin: 0 0.033rem;
    border-radius: 100%;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.47rem;
    font-size: 0.33rem;
    text-align: center;
}
.ball_xs {
    display: flex;
}
.ball_xs .ball {
    background: red;
    color: #fff;
    margin: 0 0.04rem;
    border-radius: 100%;
    width: 0.7rem;
    height: 0.7rem;
    line-height: 0.65rem;
    font-size: 0.53rem;
    text-align: center;
}
.record_bet {
    min-height: 3.27333rem;
    background: #ffffff;
    border-radius: 0.53333rem;
    font-size: 0.444rem;
    color: #8b8b8b;
    position: relative;
    z-index: 1;
}
.colum-resultxs {
    display: flex;
    flex-flow: row wrap;
    padding: 0.3rem 0 0;
}
.colum-resultxs > .col-50 {
    width: 50%;
    padding: 0.1rem 0.3rem 0.1rem;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
}
.colum-resultxs > .col-50 .info_bet {
    width: 100%;
    white-space: nowrap;
}
.colum-resultxs > .col-100 {
    width: 100%;
    padding: 0.3rem 0.5rem 0.5rem;
}
.colum-resultxs > div:first-child {
    border-right: 1px solid #eee;
}
.xs_before {
    margin: 0.2rem 0 0.3rem;
    color: #000;
    font-weight: 600;
}
.record_bet .count {
    height: 0.8rem;
    line-height: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0 0;
}
.record_bet .count > div {
    background: #efeff4;
    border-radius: 0.05333rem;
    color: red;
    font-weight: 700;
    font-size: 0.48rem;
    text-align: center;
    padding: 0 0.13333rem;
    border: 0.00667rem solid #fff;
}
.record_bet .count2 {
    height: 1.2rem;
    line-height: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0 0;
}
.record_bet .count2 > div {
    background: #efeff4;
    border-radius: 0.05333rem;
    color: red;
    font-weight: 700;
    font-size: 0.6rem;
    text-align: center;
    padding: 0 0.23333rem;
    margin: 0 0.05rem;
    border: 0.00667rem solid #fff;
}
.record_bet .count > .notime,
.record_bet .count2 > .notime {
    background: transparent;
}
.btn-mini {
    padding: 0 0.15rem;
    height: 0.61333rem;
    font-size: 0.25333rem;
    text-align: center;
    border: 0.01333rem solid red;
    border-radius: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
    background: #fff;
    margin: auto;
}
.btn-medium {
    height: 1.16667rem;
    color: #fff;
    font-size: 0.38rem;
    letter-spacing: 0.03333rem;
    border-radius: 1.06667rem;
    border: none;
    background: #fb0e02;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin: 0.5rem auto 0;
}
.text_choose_center {
    background: #6f0303;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.26667rem 0.34667rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.2rem;
}
.text_choose_center2 {
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center3 {
    background: #ffb30099;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    border-radius: 0.26667rem;
    padding: 0.3rem 0.3rem 0.5rem;
    text-align: center;
    font-size: 0.34667rem;
    margin-top: 0.33rem;
}
.text_choose_center3.t2a {
    background: #1ac52999;
}
.text_choose_center3.t3a {
    background: #ff6c0099;
}
.text_choose_center3 .state_choose_price > div {
    background: #fff;
    color: #333;
}
.text_choose_center3 .state_choose .chooseItem {
    background: red;
    color: #fff;
}
.text_choose_center .bet_state {
    font-size: 0.3777rem;
    font-weight: bold;
    margin: 0.1rem 0 0.3rem;
    display: inline-block;
    color: #fff;
}
.text_choose_center textarea {
    border: 0;
    outline: none;
    resize: none;
    font-size: 0.33rem;
    width: 100%;
    border-radius: 0.15rem;
    min-height: 5rem;
    padding: 0.15rem;
}
.text_choose_center .cach-choi {
    color: #fff;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 0.3rem;
}
.state_choose {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
}
.state_choose > div {
    flex: 1;
    margin: 0.1rem;
    background: #e5e5e5;
    border: 0;
    border-radius: 0.15rem;
    padding: 0.28rem;
    cursor: pointer;
}
.state_choose_price > div {
    width: calc(25% - 0.2rem);
    flex: 0 0 calc(25% - 0.2rem);
    padding: 0.28rem 0;
}
.state_choose2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
}
.state_choose > div > * {
    display: block;
}
.state_choose2 > div {
    background: #fff;
    padding: 0.6rem 0.28rem;
}
.state_choose > div > i {
    font-size: 0.35rem;
    color: #00b6ff;
    margin-bottom: 0.2rem;
    font-weight: bold;
}
.state_choose .chooseItem {
    background: #00b6ff;
    color: #fff;
}
.state_choose .chooseItem * {
    color: #fff !important;
}
.state_choose .choose_xs {
    width: calc(10% - 0.1rem);
    padding: 0.1rem 0;
    margin: 0.05rem;
    flex: unset;
}
.popup-bet {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    box-shadow: 0 0.05333rem 0.10667rem #c5c5da40;
    width: 100%;
    max-width: 540px;
    z-index: 101;
    padding: 0.3rem;
}
.main:has(.chooseItem) .popup-bet {
    display: block;
}
.main:has(.chooseItem) .footer {
    display: none;
}
.main:has(.tab-game) .footer {
    display: flex !important;
}
.item_choose_footer {
    display: flex;
    justify-content: space-between;
    font-size: 0.35rem;
}
.item_choose_footer .btn-sbmit {
    color: #fff;
    font-size: 0.3rem;
    font-weight: bold;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 1.06667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    padding: 0.1rem 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.item_choose_footer input {
    padding: 0.16rem 0.14667rem;
    margin: 0 0 0 0.2rem;
    font-size: 0.27333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
    border: 1px solid #e5e5e5;
}
.popup-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    height: 100vh;
    padding: 0.44rem;
    background: #000000c2;
    z-index: 9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.popup-main {
    border-radius: 0.44rem;
    overflow: hidden;
    background: #fff;
    width: 100%;
}
.popup-header {
    background: #3d0101;
    color: #fff;
    font-size: 0.45rem;
    font-weight: bold;
    padding: 0.32rem;
}
.popup-content {
    padding: 0.32rem;
    font-size: 0.35rem;
    text-align: left;
    line-height: 1.3;
    max-height: 70vh;
    overflow: auto;
}
.popup-close {
    color: #fff;
    font-size: 0.35rem;
    font-weight: bold;
    text-shadow: 0 0.05333rem 0.02667rem #ff0000;
    border-radius: 0.66667rem;
    border: none;
    background: linear-gradient(180deg, #ee8d8d 0%, #ff0000 100%);
    box-shadow: 0 0.05333rem #e53636;
    padding: 0.15rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.32rem auto;
}
.content-history {
    position: relative;
    color: #b7b7b7;
    font-size: 0.3rem;
    height: 100%;
    overflow-y: auto;
}
.content-history .item_inner {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    border-bottom: 1px solid #636363;
}
.content-history .item_history {
    text-align: left;
}
.content-history .id_history_sanh {
    margin: 0.2rem 0 0.08rem;
}
.content-history .sanh {
    color: #bcbcbc;
    font-size: 0.36rem;
    font-weight: bold;
}
.content-history .money_history {
    text-align: right;
}
.content-history .money {
    color: #fff;
    display: block;
    margin: 0.2rem 0;
    font-size: 0.35rem;
}
.content-history .type_state {
    margin-left: 0.15rem;
    padding: 0.05rem 0.1rem;
    border-radius: 0.1rem;
    color: #fff;
    display: inline-block;
}
.win {
    background-color: #6fe26f;
}
.lose {
    background-color: #b12424;
}
.pending {
    background-color: #dba700;
}
.cancelled {
    background-color: #61585a;
}
.cancel-bet {
    background: #2492b1;
    cursor: pointer;
}
.swal-button {
    background: red;
}
.swal-footer {
    text-align: center;
}
.swal-modal:has(.swal-icon--success) .swal-button {
    background: #a5dc86 !important;
}
.swal-modal:has(.swal-icon--error) .swal-button {
    background: #f27474 !important;
}
.swal-modal:has(.swal-icon--warning) .swal-button {
    background: #f8bb86 !important;
}
.swal-modal:has(.swal-icon--info) .swal-button {
    background: #3fc3ee !important;
}
.content_profile {
    font-size: 0.4rem;
}
.content_bank {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    color: #666;
    margin: 0.5rem 0;
    font-size: 0.32rem;
}
.content_bank:not(:has(.item-banks:nth-child(2))) {
    font-size: 0.4rem;
}
.content_bank:not(:has(.item-banks:nth-child(2))) > div {
    width: 100%;
}
.content_bank > div {
    width: 100%;
    background: #620101;
    border-radius: 5px;
    color: #fefafa;
    font-size: 0.333rem;
    padding: 0.2rem 0.32rem;
    text-align: left;
    margin-top: 0.2rem;
    position: relative;
    line-height: 1.5;
}
.content_bank .copystk {
    position: absolute;
    right: 0.2rem;
    font-size: 12px;
    color: #fff;
    background: rgba(0, 0, 0, 0.4);
    padding: 3px 6px;
    border-radius: 3px;
    cursor: pointer;
}
.content_bank > div:nth-child(even) {
    border-left: 0;
}
.content_bank b {
    color: #fff;
}
.huongdan ul li {
    list-style: none;
    margin-bottom: 0.2rem;
    text-align: left;
    position: relative;
    padding-left: 0.4rem;
    font-size: 0.35rem;
    color: #eee;
}
.huongdan ul li:before {
    position: absolute;
    content: "";
    width: 0.13333rem;
    height: 0.13333rem;
    background: red;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    left: -0.06667rem;
    top: 0.15333rem;
}
.table-result {
    text-align: center;
    font-family: arial;
    width: 100%;
}
.table-result td {
    text-align: center !important;
}
.table-result th {
    border-right: 1px solid #ebebeb;
}
.table-result td,
.table-result th {
    vertical-align: middle;
}
.special-prize {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 28px;
    font-weight: bold;
    color: #ed1c25;
}
.prize1 {
    float: left;
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize2 {
    float: left;
    width: 50%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize3 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize4 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize5 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize6,
.prize8 {
    float: left;
    width: 33.3%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.48rem;
    font-weight: bold;
}
.prize7 {
    float: left;
    width: 25%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-weight: bold;
    font-size: 0.48rem;
}
[class*="prize"]:empty {
    display: none;
}
.bacang {
    margin-bottom: 0.3rem;
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #b7b7b7;
}
.tab-3cang {
    margin-left: -0.2rem;
    margin-right: -0.2rem;
    margin-top: 0.3rem;
}
.tab-3cang > li {
    margin: 0 0.05rem;
    font-size: 0.3rem;
    background: none;
    border: 1px solid #ffc107;
    line-height: 0.222rem;
    padding: 0.1333rem 0;
}
.tab-3cang > li.active {
    background: #ffc107;
}
.tab-3cang > li > div {
    width: 100%;
    height: 33.33%;
}
.tab-game > li {
    margin: 0 0.1rem 0.2rem;
    font-size: 0.3rem;
    min-width: 100px;
}
.box-image {
    box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.06);
    background: #fff;
    border-radius: 0.15rem;
    overflow: hidden;
    margin-bottom: 0.35rem;
}
.box-image-title {
    font-size: 0.3rem;
    padding: 0.1rem 0.2rem 0.3rem;
}
.demo {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 99;
    background: #0000009d;
}
.loading {
    position: fixed;
    width: 100%;
    max-width: 540px;
    height: 100vh;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    z-index: 999;
    background: #0000009d;
}
body:has(.info_bet) .loading {
    display: none !important;
}
.loader {
    position: relative;
    overflow: hidden;
    display: block;
    height: 50px;
    width: 50px;
    margin: 0;
}
.loader:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 28px;
    height: 28px;
    margin: -16px 0 0 -16px;
    border: 2px solid red;
    border-top-color: #e1e8ed;
    border-right-color: #e1e8ed;
    border-radius: 100%;
    animation: spinner 1s infinite linear;
    transform-origin: center;
}
@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(1turn);
        transform: rotate(1turn);
    }
}
.route_game input {
    border: 1px solid #e5e5e5;
}
body:has(.admin-theme) .table-result th,
body:has(.admin-theme) .table-result td span,
body:has(.admin-theme) .table-result td {
    font-size: 20px !important;
    margin: 10px 0;
}
body:has(.admin-theme) .table-result td input {
    position: relative;
    top: -10px;
}
.button-admin {
    background: red !important;
    color: #fff !important;
    margin: 5px !important;
}
body:has(.admin-theme) input {
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    padding: 5px;
    font-size: 16px;
    max-width: 100%;
}
.col-1-1 {
    float: left;
    width: 50%;
    max-width: 250px;
    padding: 5px;
}
.form-admin {
    max-width: 400px;
    margin: 0 auto 15px;
    text-align: left;
}
.form-admin > div > div {
    margin: 15px 0;
    display: flex;
}
.form-admin label {
    width: 180px;
}
.form-admin button {
    margin: auto;
    display: block;
}
body:has(.admin-theme) h2 {
    margin: 15px 0 5px;
}
body:has(.admin-theme) #webpack-dev-server-client-overlay {
    display: none !important;
}
.promotionRule__container-content__rules-item {
    position: relative;
    padding: 0.57333rem 0.24rem 0.33333rem;
    border: 0.01333rem solid #ff6464;
    border-radius: 0.26667rem;
    border-top-right-radius: 0.26667rem;
    background: #fff;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}
.promotionRule__container-content__rules-item__splitBorder {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    line-height: 0.1;
    text-align: center;
}
.promotionRule__container-content__rules-item__splitBorder > span {
    position: relative;
    top: -0.16rem;
    display: inline-block;
    width: 2.4rem;
    border-top: 0.02667rem solid #fff;
}
.promotionRule__container-content__rules-item__borderTopStyle {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 100%;
}
.promotionRule__container-content__rules-item__borderTopStyle span {
    position: absolute;
    top: 0;
}
.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type {
    left: -0.01333rem;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #ff6464;
    border-left: 0.06667rem solid #ff6464;
    border-top-left-radius: 0.26667rem;
}
.promotionRule__container-content__rules-item__borderTopStyle span:first-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    left: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #ff6464;
}
.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type {
    right: 0;
    width: 0.68rem;
    height: 0.68rem;
    border-top: 0.06667rem solid #ff6464;
    border-right: 0.06667rem solid #ff6464;
    border-top-right-radius: 0.26667rem;
}
.promotionRule__container-content__rules-item__borderTopStyle span:last-of-type:after {
    content: "";
    position: absolute;
    top: 0.29333rem;
    right: 0.2rem;
    width: 0.13333rem;
    height: 0.13333rem;
    border-radius: 50%;
    background-color: #ff6464;
}
.promotionRule__container-content__rules-item__titleLeft {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% - 1.2rem);
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}
.promotionRule__container-content__rules-item__title {
    position: absolute;
    top: -0.26667rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translate(-50%);
    width: 2.13333rem;
    height: 0.53333rem;
    color: #fff;
    font-size: 0.32rem;
    text-align: center;
    line-height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
    clip-path: polygon(7% 0%, 93% 0%, 100% 50%, 93% 100%, 7% 100%, 0% 50%);
}
.promotionRule__container-content__rules-item__titleRight {
    position: absolute;
    top: -0.26667rem;
    left: calc(50% + 1.2rem);
    -webkit-transform: translateX(-50%) rotate(180deg);
    transform: translate(-50%) rotate(180deg);
    width: 0.26667rem;
    height: 0.53333rem;
    background-color: #ff6464;
    -webkit-clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 0%, 50% 50%, 100% 100%, 50% 100%, 0% 50%);
    z-index: 5;
}
.promotionRule__container-content__rules-item p {
    color: #666;
    font-size: 0.32rem;
    letter-spacing: 0.01333rem;
    line-height: 0.50667rem;
}
.countdown > div {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.37333rem;
    color: #333;
    font-weight: 400;
}
.countdown > div > div {
    font-size: 0.53333rem;
    height: 0.8rem;
    border-radius: 0.13333rem;
    padding: 0 0.26667rem;
    line-height: 0.8rem;
    background: #efeff4;
    border-radius: 0.08rem;
    margin-left: 0.21333rem;
    color: #00b977;
    text-align: center;
}
.tkq {
    color: rgb(71, 123, 255);
    font-size: 0.4rem;
    font-weight: bold;
    display: block;
    margin-top: 0.2rem;
}
.time-box {
    background: #ca5959;
    border-radius: 2rem;
    color: #fefefd;
    margin-top: 0.2rem;
    display: inline-block;
    padding: 0.03rem 0.25rem;
}
.time-box .number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}
.time-box .number .item {
    padding: 2px;
    font-size: 0.4rem;
}
.btn-login {
    position: relative;
    background: linear-gradient(98.08deg, #eb0010 34.88%, #ad000c 79.3%);
    border-radius: 8px;
    color: snow;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.3rem;
    font-weight: 600;
    padding: 0.2rem 0.3rem;
    margin-right: 0.3rem;
    letter-spacing: 0.03em;
    overflow: hidden;
    transition: all 0.4s ease;
}
.btn-login:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 12px solid #ff313f;
    border-right: 32px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.btn-login:after {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 12px solid #8f000a;
    border-left: 32px solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
}
.btn-register {
    position: relative;
    background: linear-gradient(92.18deg, #ffd600 15.79%, #f90 83.52%);
    border-radius: 8px;
    color: #8f000a;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
    font-size: 0.3rem;
    font-weight: 600;
    padding: 0.2rem 0.3rem;
    letter-spacing: 0.03em;
    overflow: hidden;
    transition: all 0.4s ease;
}
.btn-register:before {
    content: "";
    width: 0;
    height: 0;
    border-top: 12px solid hsla(0, 0%, 100%, 0.4);
    border-right: 32px solid transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.btn-register:after {
    content: "";
    width: 0;
    height: 0;
    border-bottom: 12px solid rgba(255, 125, 0, 0.8);
    border-left: 32px solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
}
.btn-login:hover,
.btn-register:hover {
    opacity: 0.85;
}
.contentHtml {
    max-height: 300px;
    overflow-y: auto;
}
.contentHtml::-webkit-scrollbar {
    display: none;
}
.btn-marquee {
    background: linear-gradient(180deg, #f9e508 0%, #fb9209 100%);
    color: #fff;
    border: 0;
    border-radius: 0;
    padding: 5px 10px;
    cursor: pointer;
}
.marquees {
    display: flex;
    font-size: 14px;
    align-items: center;
    justify-content: left;
    color: #fff;
    overflow: hidden;
}
.marquees p {
    line-height: 1.5;
}
.marquees > div {
    line-height: 1;
    flex: 1;
    padding: 5px 15px;
    background: #3d0101;
    position: relative;
    z-index: 1;
}
.marquees > div:first-child {
    z-index: 2;
}
.marquees > div:first-child {
    flex: 0 0 50px;
    text-align: left;
    color: #f9e508;
}
.marquee {
    width: calc(100% - 140px);
    overflow: hidden;
    white-space: nowrap;
    line-height: 1;
}

.marquee > p {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    animation: marquee 10s linear infinite;
    line-height: 1.3;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.quill {
    width: 100%;
    margin-bottom: 40px;
    min-height: 200px;
}
.logologin {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 10%;
    width: 50%;
    max-width: 250px;
}
@media (max-width: 1600px) {
    table {
        width: 100% !important;
    }
}
.lsgd-table {
    display: flex;
    font-size: 0.32rem;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
    margin-bottom: 5px;
}
.lsgd-table > div {
    width: calc(100% - 100px);
    max-width: calc(100% - 100px);
    text-align: left;
    max-height: 300px;
    overflow-y: auto;
}
.lsgd-table > div:first-child {
    width: 100px;
}
.table-lsgd td {
    padding: 0 0.1rem;
}
.table-result td span:empty {
    display: none;
}
.table-result td {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.MuiBox-root form + table {
    margin-top: 20px;
}
[class*="btn-admin"] {
    border: 0;
    border-radius: 5px;
    color: #fff;
    background: red;
    box-shadow: none;
    padding: 7px 12px;
    margin-left: 3px;
}
.btn-submit.btn-admin-1 {
    background: #54bd33;
    padding: 7px 12px;
}
.btn-submit.btn-admin-2 {
    background: #9d8ae7;
    padding: 7px 12px;
}
.MuiTable-root {
    width: 100% !important;
}
@media (min-width: 1200px) {
    .MuiTable-root {
        min-width: 1200px;
    }
}
.MuiTable-root th:last-child {
    text-align: center;
    justify-content: center;
}
.MuiTable-root td:last-child {
    text-align: center;
    justify-content: center;
}
.result_admin_choose table,
.max900 {
    max-width: 900px;
}
.current_bet {
    border: 0.5px solid #eee;
    margin-bottom: 20px;
}
.current_bet > div {
    display: flex;
}
.current_bet > div > div {
    padding: 10px;
    flex: 1;
    border: 0.5px solid #eee;
}
.current_bet > div:first-child {
    font-weight: bold;
}
.table_ls_tx th {
    font-size: 0.4rem;
    padding: 0.1rem;
}
.table_ls_tx td {
    font-size: 0.3rem;
    padding: 0.1rem;
    vertical-align: middle;
}
.table_ls_tx .history_xucsac > div {
    width: 0.44rem;
    height: 0.44rem;
    background-size: 0.44rem;
}
.t-blue {
    color: #4c49e3;
}
.t-green {
    color: #32c977;
}
.banglaisuat {
    margin: 30px 0 10px;
}
.banglaisuat tbody {
    border-left: 1px solid #ebebeb;
    border-right: 1px solid #ebebeb;
    background: #fff;
}
.banglaisuat td {
    padding: 5px 10px;
    font-size: 15px;
    text-align: center !important;
}
.btn-1 {
    background: #ffd3d3;
    color: #d32f2f;
    border: 0;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
    font-size: 0.333333rem;
    margin-bottom: 0.3rem;
}
.btn-2 {
    background: #d32f2f;
    color: #fff;
    border: 0;
    border-radius: 5px;
    width: 120px;
    padding: 10px;
    font-size: 0.333333rem;
    margin-bottom: 0.3rem;
}
.tongloinhuan {
    position: relative;
    font-size: 0.4rem;
    top: -20px;
    margin-bottom: -10px;
    color: #fbff59;
}
.bangthongke td {
    font-size: 1rem;
    padding: 10px;
    text-align: left;
}
.avb_ {
    margin-bottom: 30px;
}
@media (min-width: 1200px) {
    .avb_ {
        column-count: 2;
    }
}
.form-box {
    background: #2c0000;
    border-radius: 0.26667rem;
    padding: 0.32rem;
    margin: 0;
}
.form-box label {
    color: #fefdfd;
    font-size: 0.33rem;
    margin: 0 0 0.15rem;
}
.form-box input {
    background: #fff;
    width: 100%;
    height: 1rem;
    color: #333;
}
.form-box .input {
    margin-bottom: 0.4rem;
}
.form-box select {
    width: 100%;
    height: 1rem;
    padding: 0.1rem 0.34667rem;
    margin: 0 0 0.2rem;
    font-size: 0.3333rem;
    border: none;
    border-radius: 0.26667rem;
    box-shadow: 0 0.05333rem 0.21333rem #d0d0ed5c;
}
.form-box button {
    height: 1rem;
}
.menu-recharge {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0.4rem;
    margin: 0.4rem 0;
}
.menu-recharge .name {
    color: #fff;
    font-size: 0.28rem;
}
.menu-recharge > div {
    background: #2b0000;
    box-shadow: none;
    border: none;
    align-items: center;
    display: flex;
    cursor: pointer;
}
.title-xs {
    background: #1b0106;
    padding: 0.25rem 0.32rem;
    display: flex;
    justify-content: space-between;
    margin: 0.2rem 0;
}
.title-xs .title-gradient {
    font-size: 0.5rem;
    font-weight: 700;
    background: -webkit-linear-gradient(#f9e508, #fb9209);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 30px;
    line-height: 30px;
}
.title-xs select {
    background: #3d0101;
    border: 0 !important;
    color: #ca5959;
    padding: 5px 10px;
}
.list-xs {
    display: flex;
    flex-flow: row wrap;
    align-items: end;
}
.list-xs > * {
    padding: 0.2rem 0.4rem;
    max-width: 33.3333%;
}
.game-detail {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 0.32rem;
}
.game-detail img {
    width: 28%;
    padding-right: 2%;
}
.game-detail > div {
    width: 36%;
    padding-left: 3%;
}
.game-detail > div:nth-child(2) {
    text-align: left;
}
.game-detail-title {
    font-size: 0.44444rem;
    font-weight: 700;
    line-height: 2;
    text-transform: uppercase;
    background: -webkit-linear-gradient(#f9e508, #fb9209);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: -0.3rem;
}
.game-detail-des {
    color: #fff;
}
.game-result {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.32rem;
    background: #1b0106;
    color: #f1f0f0;
    align-items: center;
}
.game-result-left {
    display: flex;
    align-items: center;
    width: 140px;
}
.game-result-left img {
    width: 24px;
}
.game-result-left span {
    height: 24px;
    line-height: 24px;
    font-size: 0.28rem;
    display: inline-block;
    margin-left: 5px;
}
.game-result-center {
    display: flex;
    justify-content: space-around;
}
.game-result-center .ball {
    padding: 0 0.1rem;
    width: 1rem;
}
.game-result-right {
    text-align: right;
    width: 140px;
}
.footer-game-top input {
    border-radius: 0.15rem;
    border: 0;
    font-size: 0.3rem;
    padding: 0.2rem;
    width: 1.5rem;
    border: 0.8px solid #c7c7c7;
}
.main-game {
    padding: 0 0.32rem 3rem;
}
.popup-change-game {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    background: rgba(27, 1, 6, 0.89);
    padding: 0 0.32rem 0.32rem;
    opacity: 0;
    max-height: 350px;
    overflow: auto;
    transform: translateY(-30%);
    transition: all 0.3s ease;
    z-index: -1;
}
.popup-change-game::-webkit-scrollbar {
    display: none;
}
.popup-change-game.active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease;
    z-index: 1;
}
.tab-navigation-game {
    text-align: left;
}
.tab-navigation-game > div {
    font-size: 0.333rem;
    border: 1px solid #f5c25f;
    border-radius: 0.15rem;
    width: 47%;
    display: inline-block;
    margin: 1.5%;
    color: #f1f0f0;
    padding: 0.2rem;
    text-align: center;
}
.tab-navigation-game > .active {
    background: linear-gradient(#f9e508, #fb9209);
}
.tab-navigation-game > .tab-game-title {
    background: -webkit-linear-gradient(#f9e508, #fb9209);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: left;
    width: 100%;
    border: 0;
    padding: 0;
    margin-top: 4%;
}
.history-sidebar {
    position: relative;
    overflow: hidden;
}
.history-backdrop {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 540px;
    height: 100vh;
    padding: 0;
    background: #000000c2;
    z-index: -9999;
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    overflow: hidden;
    opacity: 0;
    transition: all 0.4s ease;
}
.history-sidebar.active .history-backdrop {
    z-index: 9999;
    opacity: 1;
    transition: all 0.4s ease;
}
.history-sidebar .history-main {
    position: absolute;
    right: 0;
    left: auto;
    width: 80%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    background: #3d0101;
    transform: translateX(80%);
    transition: all 0.4s ease;
}
.history-sidebar.active .history-main {
    transform: translateX(0);
    transition: all 0.4s ease;
}
.history-header {
    background: #1b0101;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 0.4rem;
    padding: 0.2rem;
}
.history-close {
    position: absolute;
    top: 0.2rem;
    left: 0.1rem;
    color: #fff;
    font-weight: 700;
}
.chaychu {
    white-space: nowrap;
    overflow: hidden;
}
@keyframes chuchay {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
.api-game-flex {
    display: flex;
    flex-flow: row wrap;
    margin-left: -0.16rem;
    margin-right: -0.16rem;
}
.api-game-flex > .api-game {
    padding: 0 0.16rem;
    width: 50%;
}
.api-game {
    margin-top: 0.32rem;
    position: relative;
}
.table-admin td {
    padding: 0.3rem;
    font-size: 13px;
    line-height: 1.3;
    border-top: 1px solid #dee2e6;
    border-bottom: 0 !important;
}
.table-admin th {
    font-weight: bold !important;
    color: #234983 !important;
    padding: 10px 5px !important;
}
@-webkit-keyframes hot {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}
@keyframes hot {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }
}
.icon-hot {
    position: absolute;
    height: 0.693rem;
    top: -0.107rem;
    left: 2rem;
    transform: translateZ(0);
    -webkit-transform-origin: 36% 20%;
    transform-origin: 36% 20%;
    animation: hot 0.8s ease-in-out;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
.sub_logo {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 120px;
}
.sub_hot {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 80px;
}